body {
  font-family: ff-dagny-web-pro-1;
  color: rgba(123, 123, 129, 0.85);
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}
h1 {
  margin-top: 20px;
  margin-bottom: 5px;
  font-family: ff-dagny-web-pro-1, ff-dagny-web-pro-2, sans-serif;
  color: white;
  font-size: 38px;
  line-height: 41px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
h2 {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: ff-dagny-web-pro-1;
  color: #0056a3;
  font-size: 28px;
  line-height: 36px;
  font-weight: 700;
  text-transform: uppercase;
}
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}
h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}
p {
  margin-top: 20px;
}
.button {
  display: inline-block;
  overflow-x: visible;
  overflow-y: visible;
  margin-right: 5px;
  margin-left: 5px;
  padding: 15px 25px;
  float: none;
  clear: none;
  border: 1px solid white;
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  font-family: ff-dagny-web-pro-1;
  color: white;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}
.button:hover {
  background-color: white;
  color: #0056a3;
}
.body-section {
  margin-top: 100px;
}
.body-section.footer {
  margin-top: 150px;
  padding: 30px 35px;
  border-top: 1px solid #f4f4f5;
}
.hero-section {
  background-image: url('../images/bg_pattern.jpg');
  background-size: 297px;
  background-attachment: fixed;
}
.intro-paragraph {
  margin-top: 0px;
  font-family: ff-dagny-web-pro-1;
  color: white;
  font-size: 32px;
  line-height: 40px;
  font-weight: 300;
  text-align: center;
}
.hero-messaging {
  padding-top: 6vh;
  padding-bottom:64px;
}
.notcher {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
.quote-section {
  margin-top: 120px;
  margin-bottom: 60px;
  background-image: url('../images/bg_pattern.jpg');
  background-size: 297px;
  background-attachment: fixed;
}
.quote {
  margin-top: 80px;
  font-family: ff-tisa-web-pro-1;
  color: white;
  font-size: 24px;
  line-height: 40px;
  font-style: italic;
  font-weight: 400;
  text-align: center;
}
.byline {
  margin-top: 12px;
  margin-bottom: 80px;
  font-family: ff-dagny-web-pro-1;
  color: #a3b7d3;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
}
.byline.contact-text {
  display: block;
  margin-top: 31px;
  margin-bottom: 0px;
  float: right;
  clear: none;
  opacity: 1;
  color: #a3b7d3;
  text-align: right;
}
.logo {
  margin-top: 27px;
}
.header {
  padding-right: 35px;
  padding-left: 35px;
}
.lists {
  line-height: 35px;
  list-style-type: square;
}
.navigation {
  display: block;
  margin-top: 20px;
  margin-bottom: 64px;
  padding-left: 168px;
}
.link {
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #0056a3;
  text-decoration: none;
}
.link:hover {
  text-decoration: underline;
}
.footer-text {
  font-family: ff-dagny-web-pro-1, ff-dagny-web-pro-2, sans-serif;
  color: #b1b1b5;
  font-size: 13px;
  line-height: 20px;
}
.icon-email {
  margin-top: 32px;
  margin-right: 7px;
  margin-left: 15px;
  float: right;
}
.icon-phone {
  margin-top: 32px;
  margin-right: 7px;
  margin-left: 5px;
  float: right;
}
.divider {
  display: block;
  margin-right: auto;
  margin-left: auto;
  border-bottom: 3px solid #0056a3;
}
.link-white {
  opacity: 1;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #a3b7d3;
  text-decoration: none;
}
.link-white:hover {
  opacity: 1;
  color: white;
}
.center {
  max-width: 720px;
  margin: 0 auto;
}

.video-wrapper {
  max-width:720px;
  margin:0 auto;
}
.video-container {
  position:relative;
  height:0;
  padding-bottom:56.25%;
}
.video-iframe {
  position:absolute; width:100%; height:100%;
}

@media (max-width: 991px) {
  h1 {
    margin-bottom: 0px;
    font-size: 33px;
  }
  h2 {
    font-size: 25px;
  }
  p {
    font-size: 15px;
  }
  .body-section {
    margin-top: 60px;
  }
  .body-section.footer {
    padding-right: 25px;
    padding-left: 25px;
  }
  .intro-paragraph {
    font-size: 29px;
    line-height: 39px;
  }
  .hero-messaging {
    padding-top: 6vh;
  }
  .quote {
    margin-top: 70px;
  }
  .byline {
    margin-bottom: 70px;
  }
  .header {
    padding-right: 25px;
    padding-left: 25px;
  }
  .navigation {
    margin-bottom: 32px;
    padding-left: 62px;
  }
}
@media (max-width: 767px) {
  h1 {
    font-size: 28px;
  }
  .button {
    display: block;
    margin: 10px auto;
    float: none;
  }
  .body-section {
    padding-right: 15px;
    padding-left: 15px;
  }
  .intro-paragraph {
    font-size: 23px;
    line-height: 35px;
  }
  .hero-messaging {
    padding-top: 3vh;
    padding-bottom:32px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .quote-section {
    margin-top: 100px;
    padding-right: 25px;
    padding-left: 25px;
  }
  .quote {
    margin-top: 50px;
    text-align: left;
  }
  .byline {
    margin-bottom: 50px;
    text-align: left;
  }
  .logo {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .navigation {
    display: block;
    margin-right: auto;
    margin-bottom: 32px;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (max-width: 479px) {
  h1 {
    font-size: 25px;
    line-height: 30px;
  }
  h2 {
    margin-top: 10px;
  }
  .intro-paragraph {
    margin-top: 5px;
    font-size: 18px;
    line-height: 26px;
  }
  .hero-messaging {
    padding-top: 30px;
  }
  .quote-section {
    margin-top: 65px;
  }
  .quote {
    margin-top: 40px;
    font-size: 20px;
    line-height: 32px;
  }
  .byline {
    margin-bottom: 50px;
  }
  .lists {
    font-size: 15px;
    line-height: 25px;
  }
  .navigation {
    padding-right: 8px;
    padding-left: 8px;
  }
  .footer-text {
    font-size: 10px;
  }
}
